.legend {
  align-items: center;
  display: flex;
  margin: 10px 0px;
}

.legend--symbol {
  border-radius: 1px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
  height: 25px;
  margin-right: 5px;
  width: 25px;
}

.legend--symbol--before {
  background-color: var(--viz-cashout-before);
}

.legend--symbol--after {
  background-color: var(--viz-cashout-after);
}

.legend--label {
  margin-right: 20px;
}
