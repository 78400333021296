.tax {
  background-color: var(--brand-lighten-45);
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  margin-bottom: 15px;
  padding: 20px 30px 30px;
}

.tax--header {
  align-items: center;
  color: var(--brand-darken-35);
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.tax--header h2 {
  margin-left: 5px;
}

.tax--body {
  color: var(--brand-darken-35);
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.4;
}
