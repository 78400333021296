.intro {
  margin-bottom: 15px;
}

.intro--header {
  color: var(--brand);
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}

.intro--detail {
  color: var(--brand-darken-35);
  font-size: 1rem;
  line-height: 1.4rem;
  background: var(--brand-opposite-lighten-47);
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.intro--emphasis {
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
}

.intro--emphasis-negative {
  color: var(--alert);
}

.intro--highlight {
  background-color: var(--highlight);
}

.intro--instruction {
  display: inline-block;
  margin-top: 5px;
}

.intro--list {
  display: flex;
  font-size: 1.2em;
  font-weight: 500;
  justify-content: space-between;
  line-height: 1.5em;
  list-style: none;
  margin-bottom: 10px;
}

.intro--list > li {
  padding-left: 2rem;
  background-image: url(./check.svg);
  background-position: 0 2.5px;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  vertical-align: middle;
}

@media (min-width: 320px) and (max-width: 480px) {
  .intro--header {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .intro--list {
    display: block;
  }

  .intro--detail {
    font-size: 0.9rem;
    padding: 5px 10px;
  }
}
