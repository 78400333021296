.data-entry {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px 10px;
}

.data-entry--form {
  align-items: center;
  display: grid;
  grid-gap: 0 50px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  max-width: 65%;
}

.data-entry-label {
  display: block;
  margin-bottom: 5px;
  position: relative;
}

.data-entry-input {
  border-radius: 2px;
  border: 1px solid var(--brand-darken-35);
  color: var(--brand-darken-35);
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 5px 0 10px;
  padding: 5px 4px 4px;
  text-align: right;
  width: 100%;
}

.data-entry--marital {
  grid-column: span 2;
  margin-bottom: 20px;
}

.data-entry--heading {
  font-size: 1.1em;
}

.data-entry--radio-options {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.data-entry--radio {
  display: none;
}

.data-entry--radio:checked + .data-entry--radio-box {
  color: var(--brand-darken-30);
  border: 1.5px solid var(--brand-darken-30);
}

.data-entry--radio-box {
  background-color: var(--brand-lighten-52);
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  color: var(--brand-desaturate-75);
  display: inline-block;
  font-size: 1.1em;
  font-weight: bold;
  padding: 30px;
  text-align: center;
  transition: color 0.2s ease;
}

.data-entry--input-wrapper {
  position: relative;
}

.data-entry--input-dollar:before {
  content: '$';
  position: absolute;
  color: var(--brand-desaturate-75);
  left: 15px;
  font-size: 1rem;
  top: 9px;
  z-index: 1;
}

select.data-entry--select {
  background-color: white;
  height: calc(2.25rem + 2px);
}

.data-entry--hint {
  color: var(--brand-desaturate-75);
  display: block;
  font-size: 0.9em;
  margin-top: 3px;
}

.data-entry--button-container {
  display: flex;
  grid-column-start: 2;
  justify-content: space-between;
}

.data-entry--hint-link {
  color: var(--brand-darken-21);
  text-decoration: none;
}

.data-entry--button {
  border-radius: 3px;
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
  width: 47%;
}

.data-entry--primary {
  background-color: var(--brand);
  color: var(--white);
}

.data-entry--secondary {
  background-color: var(--white);
  border: 1px solid var(--brand);
  color: var(--brand);
}

.data-entry--primary:hover {
  background-color: var(--brand-darken-3);
}

.data-entry--primary:focus,
.data-entry--secondary:focus,
.data-entry-input:focus {
  outline-color: var(--brand-opposite);
}

@media (max-width: 992px) {
  .data-entry--form {
    max-width: 85%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .data-entry--form {
    display: block;
    max-width: 100%;
  }

  .data-entry-input {
    margin: 5px 0 15px;
  }

  .data-entry--radio-options {
    justify-content: space-around;
  }

  .data-entry--radio-box {
    font-size: 0.9rem;
    padding: 20px;
  }

  .data-entry--radio-label {
    flex: 0 0 170px;
  }
}
