.bar-chart {
  background-color: var(--brand-lighten-52);
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  margin-bottom: 25px;
  padding: 20px 30px 30px;
  width: 100%;
}

.bar-chart--header {
  display: flex;
  align-items: center;
}

.bar-chart--icon {
  width: 40px;
  height: 40px;
}

.bar-chart--title {
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 5px;
}

.bar-chart--bar-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.bar-chart--bar-outer {
  flex: 0 0 85%;
}

.bar-chart--bar {
  height: 35px;
}

.bar-chart--bar1 {
  background-color: var(--viz-cashout-before);
}

.bar-chart--bar2 {
  background-color: var(--viz-cashout-after);
}

.bar-chart--bar-label {
  flex: 0 0 10%;
  font-weight: bold;
  margin-left: 5px;
  text-align: right;
}

.bar-chart--footer {
  color: var(--brand-desaturate-70);
  font-style: italic;
  font-weight: 300;
  margin-top: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .bar-chart {
    padding-left: 20px;
    padding-right: 20px;
  }

  .bar-chart--title {
    font-size: 1.2rem;
  }

  .bar-chart--bar-outer {
    flex: 0 0 75%;
  }
}
