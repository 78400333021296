/* http://scg.ar-ch.org/ */
/* https://www.sessions.edu/color-calculator/ */
:root {
  --white: #fcfcfc;

  --brand: #1c7dd5;

  --brand-lighten-40: #c6e0f7;
  --brand-lighten-45: #dcecfa;
  --brand-lighten-52: #fcfdff;

  --brand-darken-3: #1a75c7;
  --brand-darken-10: #1663a8;
  --brand-darken-21: #0d3b64;
  --brand-darken-30: #0a2e4e;
  --brand-darken-35: #051829;
  --brand-darken-41: #04111c;

  --brand-saturate-15: #0a7ee7;

  --brand-desaturate-70: #707981;
  --brand-desaturate-75: #76797b;

  --brand-opposite: #d5601c;
  --brand-opposite-lighten-47: #fcede5;
  --brand-opposite-lighten-50: #fdf7f3;

  --viz-cashout-before: #af8dc3;
  --viz-cashout-before-darken-30: #633f78;
  --viz-cashout-after: #7fbf7b;
  --viz-cashout-after-darken-30: #417e3d;

  --alert: #721c24;
  --alert2: #d5321c;
  --highlight: #ffff00;
  --warning: #fff3cd;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--brand-opposite-lighten-50);
  color: var(--brand-darken-41);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* enable background printing https://stackoverflow.com/a/14784655/3991687 */
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
}
