.disclaimer {
  background-color: var(--warning);
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  margin-top: 40px;
  padding: 20px 30px 30px;
}

.disclaimer--header {
  align-items: center;
  color: var(--brand-darken-35);
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.disclaimer--header h2 {
  margin-left: 5px;
}

.disclaimer--body {
  color: var(--brand-darken-35);
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.4;
}

.disclaimer--link {
  color: var(--brand-darken-21);
  display: inline-block;
  margin-top: 5px;
  text-decoration: none;
}

.disclaimer--link-sep {
  display: inline-block;
  margin: 0 5px;
}
